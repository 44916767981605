<template>
  <vxe-modal v-model="open" width="50%" height="100%" :title="formTitle" :show-zoom="true" resize :lock-view="false" :mask="true" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="申请人" prop="applicantId">
        {{ userInfo.userName }}
      </a-form-model-item>
      <a-form-model-item label="是否可摊销" prop="isAmortize">
        <a-radio-group v-model="form.isAmortize" button-style="solid" @change="onIsAmortizeChange">
          <a-radio-button v-for="(d, index) in isAmortizeOptions" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="摊销年数" prop="amortizeYears" v-if="form.isAmortize == 'Y'">
        <a-input v-model="form.amortizeYears" placeholder="请输入摊销年数" type="Number" />
      </a-form-model-item>
      <a-form-model-item label="费用类型" prop="expenseType">
        <a-select placeholder="请选择费用类型" v-model="form.expenseType" @change="onExpenseTypeChange">
          <a-select-option v-for="(d, index) in expenseTypeOptions2" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="申请时间" prop="applyTime">
        <a-date-picker style="width: 100%" v-model="form.applyTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" disabled />
      </a-form-model-item>
      <a-form-model-item label="月费用加总" prop="expenseTotal">
        <a-input v-model="form.expenseTotal" placeholder="" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="所属项目" prop="deptId">
        <a-select placeholder="请选择所属项目" v-model="form.deptId">
          <a-select-option v-for="(d, index) in projectOptions" :key="index" :value="d.deptId">{{ d.deptName }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="采购明细" class="expense-list">
        <expense-list ref="listRef" :cform="form" :crules="rules" :unitOptions="unitOptions" @setAmount="setAmount"> </expense-list>
      </a-form-model-item>
      <a-form-model-item label="申请金额" prop="applyAmount">
        <a-input v-model="form.applyAmount" placeholder="请输入申请金额" />
      </a-form-model-item>
      <a-form-model-item label="上传单据" prop="photo">
        <!-- 注意手动设置初始值：initFileList，数据单向赋值 -->
        <many-file-upload
          :init-file-list="form.initFileList"
          folder="expenses"
          type="image"
          :maxFileCount="15"
          @onUploading="uploading = $event"
          @billImageNum="form.billImageNum = $event"
          @fileList="
            form.billImageUrl = $event && $event.length > 0 ? $event.map((p) => p.url) : []
            form.billImage = $event && $event.length > 0 ? $event.map((p) => p.fileName).join(',') : ''
          "
        ></many-file-upload>
      </a-form-model-item>
      <a-form-model-item label="是否有专票" prop="isSpecialTicket">
        <a-radio-group v-model="form.isSpecialTicket" button-style="solid">
          <a-radio-button v-for="(d, index) in isAmortizeOptions" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="交易对手方" prop="counterparty">
        <a-input id="counterparty" v-model="form.counterparty" placeholder="请输入交易对手方" />
      </a-form-model-item>
      <a-form-model-item label="付款方式" prop="paymentType">
        <a-radio-group v-model="form.paymentType" button-style="solid">
          <a-radio-button v-for="(d, index) in paymentTypeOptions" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="实际付款人" prop="actualPayer" v-if="form.paymentType == 2 || form.paymentType == 3">
        <a-select
          show-search
          placeholder="请输入实际付款人姓名查找"
          v-model="form.actualPayer"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          @search="handleEmployeeSearch"
          @change="handleEmployeeChange"
          :loading="loading"
        >
          <a-select-option v-for="d in employeeArray" :key="d.id" :value="d.name">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input type="textarea" v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="loading || uploading" @click="submitForm"> {{ uploading ? '上传中' : '保存' }} </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </vxe-modal>
</template>

<script>
import debounce from 'lodash/debounce'
import { getExpenses, addExpenses, updateExpenses, countExpenses, getProjects } from '@/api/iot/expenses'
import { listEmployeeAccount } from '@/api/iot/employeeAccount'
import ExpenseList from './ExpenseList'
import { mapState } from 'vuex'
import { parseDateTime } from '@/utils/ruoyi'
import ManyFileUpload from '@/components/ManyFileUpload'
import base from '../../../base'
export default {
  mixins: [base],
  name: 'Expenses',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    approvalRecord: {
      type: Object,
      default: null
    },
    action: {
      type: String,
      default: ''
    }
  },
  components: {
    ExpenseList,
    ManyFileUpload
  },
  data() {
    this.handleEmployeeSearch = debounce(this.handleEmployeeSearch, 500)
    return {
      loading: false,
      formTitle: '费用申请',
      // 表单参数
      form: {
        applicantId: null,
        expenseType: null,
        applyTime: null,
        applyAmount: null,
        expenseTotal: null,
        isAmortize: '0',
        amortizeYears: null,
        approvalStatus: '0',
        createTime: null,
        remark: null,
        billImageUrl: null,
        initFileList: [],
        purchaseList: [
          {
            content: '',
            quantity: '',
            unit: '',
            price: '',
            amount: ''
          }
        ]
      },
      // 1增加,2修改
      formType: 1,
      open: this.isShow,
      rules: {
        expenseType: [{ required: true, message: '费用类型不能为空', trigger: 'change' }],
        applyTime: [{ required: true, message: '申请时间不能为空', trigger: 'change' }],
        applyAmount: [{ required: true, message: '申请金额不能为空', trigger: 'blur' }],
        isAmortize: [{ required: true, message: '是否可摊销不能为空', trigger: 'blur' }],
        approvalStatus: [{ required: true, message: '审批状态不能为空', trigger: 'blur' }],
        counterparty: [{ required: true, message: '交易对手方不能为空', trigger: 'blur' }],
        isSpecialTicket: [{ required: true, message: '是否有专票不能为空', trigger: 'blur' }],
        paymentType: [{ required: true, message: '付款方式不能为空', trigger: 'blur' }],
        actualPayer: [{ required: true, message: '实际付款人不能为空', trigger: 'blur' }],
        deptId: [{ required: true, message: '所属项目不能为空', trigger: 'blur' }]
      },
      expenseTypeOptions2: [],
      billImageArray: [],
      isAmortizeOptions: [],
      expenseTypeOptions: [],
      // 付款方式
      paymentTypeOptions: [],
      projectOptions: [],
      unitOptions: [],
      uploading: false
    }
  },
  filters: {},
  created() {
    console.log('expenses', 'created')
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.info
    })
  },
  watch: {},
  mounted() {
    console.log('userInfo', this.userInfo)
    this.getData()
  },
  methods: {
    async getData() {
      // 由于列表需要字典数据，所以要先获取到字典数据，再获取列表
      await this.getDictData()
      if (this.action === 'add') {
        this.handleAdd()
      } else {
        this.handleUpdate(this.approvalRecord)
      }
    },
    async getDictData() {
      this.loading = true
      await this.getDicts('sys_yes_no').then((response) => {
        this.isAmortizeOptions = response.data
      })
      await this.getDicts('iot_expense_type').then((response) => {
        this.expenseTypeOptions = response.data
      })
      await this.getDicts('iot_approval_status_type').then((response) => {
        this.approvalStatusTypeOptions = response.data
      })
      await getProjects({}).then((response) => {
        this.projectOptions = response.data
      })
      await this.getDicts('iot_account_unit').then((response) => {
        this.unitOptions = response.data.map((p) => p.dictValue)
      })
      await this.getDicts('sys_pay_type').then((response) => {
        this.paymentTypeOptions = response.data
      })

      this.loading = false
    },
    onClose() {
      this.open = false
      this.$emit('componentClose')
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
      this.$emit('componentClose')
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        template: null,
        procdefKey: null,
        applicantId: null,
        expenseType: null,
        applyTime: null,
        applyAmount: null,
        expenseTotal: null,
        isAmortize: '0',
        amortizeYears: null,
        approvalStatus: '0',
        createTime: null,
        purchaseList: [
          {
            content: null,
            quantity: null,
            unit: null,
            price: null,
            amount: null
          }
        ],
        billImage: '',
        isSpecialTicket: null,
        counterparty: null,
        paymentType: null,
        actualPayer: null,
        img: null,
        billImageUrl: null,
        initFileList: []
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '费用申请--新增'
      this.form.isAmortize = 'N'
      this.form.expenseTotal = 0
      this.form.applyTime = parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
      this.form.template = this.approvalRecord.template
      this.form.procdefKey = this.approvalRecord.approvalKind
      console.log('form', this.form)
      this.setExpenseTypeOption2('N')
      console.log('projectOptions', this.projectOptions)
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      this.formType = 2
      const id = row.approvalKindId
      getExpenses(id).then((response) => {
        this.form = response.data
        const form = { ...this.form }
        this.form.initFileList = { billImage: form.billImage, billImageUrl: form.billImageUrl }
        this.open = true
        this.formTitle = '费用申请--修改'
        this.setExpenseTypeOption2(this.form.isAmortize)
        this.$forceUpdate()
      })
    },

    doBeforeSave() {
      // 设置默认的申请人
      this.form.applicantId = this.userInfo.userId
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log('submitForm')
      if (!this.doCheckBeforeSave()) {
        return
      }
      this.doSave()
    },
    doSave() {
      this.$refs.listRef.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.form.purchaseList = this.$refs.listRef.getPurchaseList()
          console.log('purchaseList', this.form.purchaseList)
          this.$refs.form.validate((valid, obj) => {
            if (valid) {
              this.doBeforeSave()
              this.loading = true
              if (this.form.id !== undefined && this.form.id !== null) {
                updateExpenses(this.form)
                  .then((response) => {
                    this.loading = false
                    this.$message.success('修改成功', 3)
                    this.open = false
                    this.$emit('ok')
                  })
                  .catch(() => {
                    this.loading = false
                  })
              } else {
                addExpenses(this.form)
                  .then((response) => {
                    this.loading = false
                    this.$message.success('新增成功', 3)
                    this.open = false
                    this.$emit('ok')
                  })
                  .catch(() => {
                    this.loading = false
                  })
              }
            } else {
              return false
            }
          })
        }
      })
    },
    handleEmployeeSearch(value) {
      console.log('handleEmployeeSearch', value)
      const queryParam = {
        name: value,
        pageNum: 1,
        pageSize: 10
      }
      this.loading = true
      listEmployeeAccount(queryParam).then((response) => {
        this.employeeArray = response.rows
        this.loading = false
        this.$forceUpdate()
      })
    },

    handleEmployeeChange(value) {
      this.form.actualPayer = value
    },
    setAmount(amount) {
      console.log('amount', amount)
      this.form.applyAmount = amount
    },
    onIsAmortizeChange(e) {
      console.log('onIsAmortizeChange', e.target.value)
      const isAmortize = e.target.value
      this.form.expenseType = ''
      this.setExpenseTypeOption2(isAmortize)
    },
    setExpenseTypeOption2(isAmortize) {
      if (isAmortize === 'Y') {
        this.expenseTypeOptions2 = this.expenseTypeOptions.filter((p) => p.dictValue >= 100)
      } else {
        this.expenseTypeOptions2 = this.expenseTypeOptions.filter((p) => p.dictValue < 100)
      }
      this.$forceUpdate()
    },
    onExpenseTypeChange(e) {
      countExpenses(e).then((response) => {
        this.form.expenseTotal = response.expenseTotal
      })
    }
  }
}
</script>

<style lang="less" scoped>
.expense-list {
  margin-bottom: 50px;
}
</style>
